import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import PortalConfig from '../../../data/config';
import Feather from 'feather-icons';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import FormElement from '../FormElement';
import { ChevronRightIcon } from '@heroicons/react/outline';
import Alert from '../../Alerts/Alert';
import Pristine from "pristinejs";

const defaultConfig = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-danger',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'text-red-500 text-xs'
};

class EditView extends Component
{
    state = {
        name: '',
        errors: {},
        fields: [],
        files: [],
        record_info: [],
        message: '',
        loading: false,
        layout_column: 1,
        parent_id: '',
        labels: [],
        date_format: JSON.parse(Cookies.get('wx_portal_session'))['date_format'],
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleDateChange = this.handleDateChange.bind(this);
        this.fileInput = React.createRef();
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
    }

    // Set loading and message to null
    hideErrorMessage(event) {
        this.setState({
            loading: false,
            message: ''
        });
    }

    // Handle input change
    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let newState = Object.assign({}, this.state);
        if(event.target.type === 'file' && event.target.files) {
            newState['files'][name] = event.target.files[0];
            newState['fields'][name] = event.target.files[0]['name'];
        }
        else {
            newState['fields'][name] = value;
        }

        this.setState({newState});
    }

    // Handle date change
    handleDateChange(field_name, field_value) {
        let newState = Object.assign({}, this.state);
        newState['fields'][field_name] = field_value;
        this.setState({newState});
    }

    /**
     * Validate Submit the form
     */
    handleSubmit() {
        // Validate the data
        let is_validated = false;
        var pristine = new Pristine(document.getElementById(`${this.props.selected_module}-editView`), defaultConfig);
        is_validated = pristine.validate(document.querySelectorAll('input[data-pristine-required], select[data-pristine-required], textarea[data-pristine-required]'));
        if(!is_validated) {
            return false;
        }

        let post_data = new FormData();

        if(this.state.parent_id) {
            if(this.props.selected_module === 'ProjectTask') {
                post_data.append('project_id', this.state.parent_id);
            }
        }

        if(!this.state.fields) {
            this.setState({
                message: 'No fields present in the layout. Please contact admin.',
                loading: false,
            });
            return false;
        }

        Object.keys(this.state.fields).forEach((field_name) => {
            post_data.append(field_name, this.state.fields[field_name]);
        });

        // Set data for image type
        this.state.record_info.forEach((layout_info) => {
            if(layout_info.fields) {
                layout_info.fields.forEach((field_info) => {
                    if(field_info.type === 'image' || (field_info.type === 'file' || this.props.selected_module !== 'Documents')) {
                        if(this.state.files[field_info.field_name]) {
                            post_data.set(field_info.field_name, this.state.files[field_info.field_name]);
                        }
                    }
                });
            }
        });

        if(this.props.selected_module === 'Documents' && this.fileInput.current && this.fileInput.current.files[0] && this.props.record_id === 'new') {
            // Set the file information
            post_data.set('filename', this.fileInput.current.files[0]);
        }

        this.setState({ loading: true });

        let method;
        let endpoint_url;
        if(this.props.record_id === 'new') {
            method = 'post';
            endpoint_url = PortalConfig.crm_url + '/' + this.props.selected_module;
        }
        else {
            method = 'post';
            endpoint_url = PortalConfig.crm_url + '/' + this.props.selected_module + '/' + this.props.record_id;
        }

        const token = Cookies.get('wx_portal_auth');

        const config = {
            url: endpoint_url,
            method: method,
            data: post_data,
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios(config).then((response) => {
            if(response.data.status === true) {
                // Show message and redirect to Detail view of the record
                this.setState({
                    message: response.data.message,
                    loading: false,
                });

                this.props.selected_module === 'Contacts' ?
                    this.props.history.push(process.env.PUBLIC_URL + '/my-profile') :
                    this.props.history.push(process.env.PUBLIC_URL + '/' + this.props.selected_module + '/detail/' + response.data.record_id)

            }
            else {
                this.setState({
                    message: 'Something went wrong!',
                    loading: false,
                });
            }
        })
        .catch((error) => {
            let error_message;
            if(error.response) {
                error_message = error.response.data.message;
                if(error_message === undefined) {
                    error_message = error.response.statusText;
                }
            }
            else {
                error_message = error.message
            }

            // Set error status and error message
            this.setState({
                message: error_message,
                loading: false,
            });

            if (error.message === 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    componentDidMount() {
        this.getRecordInfo();
        const queryString = require('query-string');
        let parsed = queryString.default.parse(window.location.search);

        if(parsed.parent_id) {
            this.setState({
                parent_id: parsed.parent_id
            });
        }
    }

    getRecordInfo() {
        // Show loading icon
        this.setState({
            loading: true,
        });

        const token = Cookies.get('wx_portal_auth');
        const config = {
            responseType: 'json',
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios.get(PortalConfig.crm_url + '/' + this.props.selected_module + '/' + this.props.record_id + '/editview', config).then((response) => {
            let response_data = response.data;
            if(response_data.status === false) {
                this.setState({
                    message: response_data.message,
                    loading: false,
                });
            }
            else {

                this.setState({
                    name: response_data.name,
                    fields: response_data.fields,
                    record_info: response_data.record_info,
                    layout_column: response_data.layout_column,
                    loading: false,
                    labels: response.data.labels,
                });

                Feather.replace();
            }
        }).catch((error) => {
            this.setState({
                loading: false,
                message: error.message,
            });

            if (error.message === 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    render() {

        return (
            <div>
                <nav className="flex justify-between" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-2">
                        <li>
                            <div>
                                {this.props.selected_module === 'Contacts' ?
                                    <p className="text-skin-primary hover:text-skin-primary-darker">My Profile</p> :
                                    <Link className="text-skin-primary hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/${this.props.selected_module}`}>{this.props.moduleLabel}</Link>
                                }
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRightIcon className="flex-shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />
                                <p className='pl-2'>{this.state.name}</p>
                            </div>
                        </li>
                        {this.state.loading === true ?
                            <svg className="text-skin-primary-darker animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        : ''}
                    </ol>
                    <ol className="flex space-x-2">
                        <li className='mt-2'>
                            {this.props.selected_module === 'Contacts' ?
                                <Link className="font-medium text-skin-base hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/my-profile`}>{this.state.labels && this.state.labels.cancel ? this.state.labels.cancel : 'Cancel'}</Link>
                            :
                                <Link className="font-medium text-skin-base hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/${this.props.selected_module}`}>{this.state.labels && this.state.labels.cancel ? this.state.labels.cancel : 'Cancel'}</Link>
                            }
                        </li>
                        <li>
                            <button type="button" onClick={() => this.handleSubmit()} className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-muted bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1 sm:ml-3">{this.state.labels && this.state.labels.save ? this.state.labels.save : 'Save'}</button>
                        </li>
                    </ol>
                </nav>

                <form id={`${this.props.selected_module}-editView`}>

                {this.state.message ? <Alert message={this.state.message} type='danger' hideAlert={this.hideErrorMessage} /> : ''}

                {this.state.record_info.map((layout_info, layout_index) => {

                    let field_chunks = [];
                    let layout_fields = [];
                    layout_fields = Object.assign([], layout_info.fields);

                    while (layout_fields.length) {
                        field_chunks.push(layout_fields.splice(0, this.state.layout_column));
                    }

                    let column_class = 'md:grid-cols-1';
                    if(parseInt(this.state.layout_column) === 2) {
                        column_class = 'md:grid-cols-2';
                    }
                    else if(parseInt(this.state.layout_column) === 3) {
                        column_class = 'md:grid-cols-3';
                    }

                    return (
                        <div key={layout_index}>
                            <div className="py-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{layout_info.header_name}</h3>
                            </div>
                            <div className="border-t border-gray-200 py-5">
                                <div className={`grid ${column_class} gap-x-4 gap-y-8 sm:grid-cols-2`}>
                                    {field_chunks.map((fields, index) => {
                                        return (
                                            <Fragment key={index}>
                                                {fields.map((field_info, index) => {

                                                    let field_value = this.state.fields[field_info.field_name];
                                                    let mandatory_span;
                                                    if(field_info.more_info.required) {
                                                        mandatory_span = <span className="text-red-600">*</span>;
                                                    }

                                                    return (
                                                        <div className="form-group sm:col-span-1" key={index}>
                                                            <label htmlFor={field_info['field_name']} className="block text-sm font-medium text-gray-700">
                                                                {field_info['label']} {mandatory_span}
                                                            </label>
                                                            {field_info['field_name'] === 'filename' && this.props.record_id !== 'new' && this.props.selected_module === 'Documents' ?
                                                                <div className="font-bold">{field_value}</div> :
                                                                <FormElement
                                                                    module={this.props.selected_module}
                                                                    handleChange={this.handleChange}
                                                                    handleDateChange={this.handleDateChange}
                                                                    field_name={field_info.field_name}
                                                                    field_value={field_value}
                                                                    type={field_info.type}
                                                                    more_info={field_info.more_info}
                                                                    label={field_info.label}
                                                                    errors={this.state.errors}
                                                                    fileInput={this.fileInput}
                                                                    date_format={this.state.date_format}
                                                                />}
                                                        </div>
                                                    );
                                                })}
                                            </Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}

                </form>
            </div>
        );
    }
}

export default EditView;
