const custom_config = {
    crm_url: 'https://crm.webxloo.com',
};

const portal_config = {
    crm_url: custom_config.crm_url + '/service/portalapi',
    crm_main_url: custom_config.crm_url,
    portal_url: '/',
    layout: 'main',
    logo: custom_config.crm_url +'/custom/themes/default/images/company_logo.png'
};

export default portal_config;
