/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import PortalConfig from '../../../data/config';
import Feather from 'feather-icons';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import ListView from './ListView';
import ShowCaseUpdates from "./ShowCaseUpdates";
import LineItems from "./LineItems";
import { ChevronRightIcon } from '@heroicons/react/outline';
import Alert from '../../Alerts/Alert';

class DetailView extends Component
{
    state = {
        name: '',
        record_info: [],
        message: '',
        loading: false,
        layout_column: 1,
        file_name: '',
        actions: [],
        show_case_updates: false,
        case_updates: [],
        labels: [],
        line_items: [],
    }

    constructor(props) {
        super(props);
        this.downloadTheDocument = this.downloadTheDocument.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
    }

    // Set loading and message to null
    hideErrorMessage(event) {
        this.setState({
            loading: false,
            message: ''
        });
    }

    componentDidMount() {
        this.getRecordInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selected_module !== this.props.selected_module) {
            this.getRecordInfo();
        }
    }

    createMarkup(html) {
        return { __html: html };
    }

    /**
     * Download the document
     **/
    downloadTheDocument(field_name, file_name) {
        const token = Cookies.get('wx_portal_auth');
        const config = {
            responseType: 'blob',
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios.get(PortalConfig.crm_url + '/' + this.props.selected_module + '/download/' + this.props.record_id + '?field_name=' + field_name + '&file_name=' + file_name, config).then((response) => {
            let d_file_name = field_name ? file_name : this.state.file_name;
            // Get the data from server and download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.setAttribute('download', d_file_name);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }).catch((error) => {
            this.setState({
                loading: false,
                message: error.message,
            });

            if (error.message === 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    getRecordInfo() {
        // Show loading icon
        this.setState({
            loading: true,
        });

        const token = Cookies.get('wx_portal_auth');
        const config = {
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios.get(PortalConfig.crm_url + '/' + this.props.selected_module + '/' + this.props.record_id + '/detailview', config).then((response) => {
            if(response.data.status === false) {
                this.setState({
                    message: response.data.message,
                    loading: false,
                });
            }
            else {
                // Preparing new state
                let newState = {
                    name: response.data.name,
                    record_info: response.data.record_info,
                    layout_column: response.data.layout_column,
                    loading: false,
                    actions: response.data.actions,
                    show_case_updates: response.data.show_case_updates,
                    case_updates: response.data.case_updates,
                    show_project_tasks: response.data.show_project_tasks,
                    labels: response.data.labels,
                    line_items: response.data.line_items,
                }

                if(this.props.selected_module === 'Documents') {
                    newState['file_name'] = response.data.fields.filename;
                }

                this.setState(newState);

                Feather.replace();
            }
        }).catch((error) => {
            this.setState({
                loading: false,
                message: error.message,
            });

            if (error.message === 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    render() {

        let previewImageStyle = {
            border: '1px solid #ddd',
            borderRadius : '4px',
            padding: '5px',
            width: '150px',
            marginTop:'1rem'
        };

        return (
            <div>
                <nav className="flex justify-between" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-2">
                        <li>
                            <div>
                                {this.props.selected_module === 'Contacts' ?
                                    <p className="">My Profile</p> :
                                    <Link className="text-skin-primary hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/${this.props.selected_module}`}>{this.props.moduleLabel}</Link>
                                }
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRightIcon className="flex-shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />
                                <p className='pl-2'>{this.state.name}</p>
                            </div>
                        </li>
                    </ol>
                    <ol className="flex space-x-2">
                        <li>
                            {this.state.actions && this.state.actions.includes('edit') ?
                                <Link to={`${process.env.PUBLIC_URL}/${this.props.selected_module}/edit/${this.props.record_id}`} className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-muted bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1 sm:ml-3">{this.state.labels && this.state.labels.edit ? this.state.labels.edit : 'Edit'}</Link>
                            : ''}
                        </li>
                    </ol>
                </nav>

                {this.state.message ? <Alert message={this.state.message} type='danger' hideAlert={this.hideErrorMessage} /> : ''}

                {this.state.record_info.map((layout_info, layout_index) => {
                    let field_chunks = [];
                    if(layout_info.fields) {
                       while (layout_info.fields.length) {
                            field_chunks.push(layout_info.fields.splice(0, this.state.layout_column));
                        }
                    }
/*
                    let edit_view_url = '';
                    {this.props.selected_module == 'Contacts' ?
                    edit_view_url = `${process.env.PUBLIC_URL}/my-profile/edit` :
                    edit_view_url = `${process.env.PUBLIC_URL}/${this.props.selected_module}/edit/${this.props.record_id}`}
*/
                    let column_class = 'md:grid-cols-1';

                    if(parseInt(this.state.layout_column) === 2) {
                        column_class = 'md:grid-cols-2';
                    }
                    else if(parseInt(this.state.layout_column) === 3) {
                        column_class = 'md:grid-cols-3';
                    }

                    return (
                        <div key={layout_index}>
                            <div className="py-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{layout_info.header_name}</h3>
                                {layout_index === 0 ?
                                    <p>
                                        {this.props.selected_module === 'Documents' ? <button onClick={() => this.downloadTheDocument('', '')} className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-muted bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1 sm:ml-3"><span data-feather='download'> </span> Download</button> : ""}
                                    </p> : ""
                                }
                            </div>
                            <div className="border-t border-gray-200 py-5">
                                <div className={`grid ${column_class} gap-x-4 gap-y-8 sm:grid-cols-2`}>
                            {field_chunks.map((fields, index) => {
                                return (
                                    <Fragment key={index}>
                                        {fields.map((field_info, index) => {
                                            if(field_info.type === 'file' && this.props.selected_module !== 'Documents') {
                                                return (
                                                    <div key={index}>
                                                        <div className="row">
                                                            <dt className="text-sm font-medium text-gray-500">{field_info['label']}</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                <a target='_blank' href='#' onClick={() => this.downloadTheDocument(field_info.field_name, field_info['value'])}>{field_info['value'] !== 'null' ? field_info['value'] : ''}</a>
                                                            </dd>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            return (
                                                <div className="sm:col-span-1" key={index}>
                                                    <dt className="text-sm font-medium text-gray-500">{field_info['label']}</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {field_info['type'] === 'textarea' ?
                                                            <>
                                                            {field_info['value'] !== 'null' ?
                                                                <span className="" dangerouslySetInnerHTML={this.createMarkup(field_info['value'])}></span>
                                                            : ''}
                                                            </>
                                                        :
                                                            <>
                                                                {(field_info['type'] === 'image' && field_info['value']) ? <img src={`data:image/jpeg;base64,${field_info['value']}`} alt="Preview" style={previewImageStyle} /> : <span> {field_info['value'] !== 'null' ? field_info['value'] : ''} </span> }
                                                            </>
                                                        }
                                                    </dd>
                                                </div>
                                            );
                                        })}
                                    </Fragment>
                                )
                            })}
                                </div>
                            </div>
                        </div>
                    );
                })}

                {this.state.show_case_updates === 'true' ?
                    <ShowCaseUpdates labels={this.state.labels} updateLoginStatus={this.props.updateLoginStatus} caseUpdates={this.state.case_updates} recordId={this.props.record_id} />
                    : ''
                }

                {this.state.line_items.length > 0 && this.props.selected_module === 'AOS_Invoices' ?
                    <LineItems groups={this.state.line_items} />
                    : ''
                }

                {this.state.show_project_tasks === 'true' ?
                    <ListView
                        history={this.props.history}
                        updateLoginStatus={this.props.updateLoginStatus}
                        selected_module='ProjectTask'
                        page='1'
                        parent_id={this.props.record_id}
                        moduleLabel='Project Tasks'
                    />
                    : ''
                }
            </div>
        );
    }
}

export default DetailView;
