import React, { Component } from 'react';
import Login from './Components/Login/Login';
import Portal from './Components/Portal/Portal';
import PasswordReset from './Components/Login/PasswordReset';
import ForgetPassword from './Components/Login/ForgetPassword';
import Cookies from 'js-cookie';
import PortalConfig from './data/config';
import Axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import '../src/css/portal.css';
import '../src/css/custom.css';

library.add(faStroopwafel)

class App extends Component
{
	constructor(props) {
		super(props);
		let modules = [];
        let module_labels = [];
		let customer_name = '';
		let customer_id = '';
		let isLoggedIn = false;
		let portal_token = Cookies.get('wx_portal_auth');
		if (portal_token) {
			let portal_information = JSON.parse(Cookies.get('wx_portal_session'));
			isLoggedIn = true;
			modules = portal_information.modules;
			customer_name = portal_information.customer_name;
			customer_id = portal_information.customer_id;
            module_labels = portal_information.module_labels;
		}

		this.state = {
			customer_name: customer_name,
			logged_in: isLoggedIn,
			modules: modules,
			customer_id: customer_id,
            module_labels: module_labels,
		}

		this.updateLoginStatus = this.updateLoginStatus.bind(this);
	}

	// Set login status
	updateLoginStatus(status) {
		let new_state = [];
		if (status === false) {
            let endpoint_url = PortalConfig.crm_url + '/log_out';
			const token = Cookies.get('wx_portal_auth');
			const pay_load = {
				url: endpoint_url,
				method: 'post',
				headers: {
					Authorization: 'bearer ' + token,
				}
			};

			Axios(pay_load).then(() => {
				// After logout
			});

			// Clear cookie on logout
			Cookies.remove('wx_portal_auth');
			Cookies.remove('wx_portal_session');
			new_state['customer_name'] = '';
			new_state['modules'] = [];
			new_state['customer_id'] = '';
			new_state['module_labels'] = '';
		}
		else {
			let customer_info = JSON.parse(Cookies.get('wx_portal_session'));
			new_state['customer_name'] = customer_info.customer_name;
			new_state['modules'] = customer_info.modules;
			new_state['customer_id'] = customer_info.customer_id;
			new_state['module_labels'] = customer_info.module_labels;
		}

		new_state['logged_in'] = status;
		this.setState(new_state);
	}

	render() {
		return (
			<div className="theme-blue">
				<Router>
					<Switch>
						<Route
							basename={PortalConfig.portal_url}
							exact path={`${process.env.PUBLIC_URL}/login`}
							render={() => (
								<Login updateLoginStatus={this.updateLoginStatus} logged_in={this.state.logged_in} />
							)}
						/>
						<Route
							basename={PortalConfig.portal_url}
							exact path={`${process.env.PUBLIC_URL}/forget-password`}
							render={() => (
								<ForgetPassword />
							)}
						/>
						<Route
							basename={PortalConfig.portal_url}
							path={`${process.env.PUBLIC_URL}/password_reset/:verification_hash`}
                            render={(props) => (
								<PasswordReset
									{...props}
								/>
                            )}
                        />
						<Route
							basename={PortalConfig.portal_url} exact
							path={`${process.env.PUBLIC_URL}/my-profile`}
                            render={(props) => (
								<Portal
									{...props}
									logged_in={this.state.logged_in}
									updateLoginStatus={this.updateLoginStatus}
									modules={this.state.modules}
                                    module_labels={this.state.module_labels}
									customer_name={this.state.customer_name}
									selected_module="Contacts"
									view="Detail"
									record_id={this.state.customer_id}
								/>
                            )}
                        />
						<Route
							basename={PortalConfig.portal_url} exact
							path={`${process.env.PUBLIC_URL}/my-profile/edit`}
                            render={(props) => (
								<Portal
									{...props}
									logged_in={this.state.logged_in}
									updateLoginStatus={this.updateLoginStatus}
									modules={this.state.modules}
                                    module_labels={this.state.module_labels}
									customer_name={this.state.customer_name}
									selected_module="Contacts"
									view="Edit"
									record_id={this.state.customer_id}
								/>
                            )}
                        />
						<Route
							basename={PortalConfig.portal_url} exact
							path={`${process.env.PUBLIC_URL}/`}
							render={(props) => (
								<Portal
									{...props}
									logged_in={this.state.logged_in}
									updateLoginStatus={this.updateLoginStatus}
									modules={this.state.modules}
                                    module_labels={this.state.module_labels}
									customer_name={this.state.customer_name}
									selected_module={props.match.params.module}
									page={props.match.params.page}
									view="dashboard"
								/>
                            )}
						/>
						<Route
							basename={PortalConfig.portal_url}
							path={`${process.env.PUBLIC_URL}/:module/page/:page`}
                            render={(props) => (
								<Portal
									{...props}
									logged_in={this.state.logged_in}
									updateLoginStatus={this.updateLoginStatus}
									modules={this.state.modules}
                                    module_labels={this.state.module_labels}
									customer_name={this.state.customer_name}
									selected_module={props.match.params.module}
									page={props.match.params.page}
									view="List"
								/>
                            )}
                        />
						<Route
							basename={PortalConfig.portal_url}
							path={`${process.env.PUBLIC_URL}/:module/detail/:id`}
                            render={(props) => (
								<Portal
									{...props}
									logged_in={this.state.logged_in}
									updateLoginStatus={this.updateLoginStatus}
									modules={this.state.modules}
                                    module_labels={this.state.module_labels}
									customer_name={this.state.customer_name}
									selected_module={props.match.params.module}
									view="Detail"
									record_id={props.match.params.id}
								/>
                            )}
                        />
						<Route
							basename={PortalConfig.portal_url}
							path={`${process.env.PUBLIC_URL}/:module/edit/:id`}
                            render={(props) => (
								<Portal
									{...props}
									logged_in={this.state.logged_in}
									updateLoginStatus={this.updateLoginStatus}
									modules={this.state.modules}
                                    module_labels={this.state.module_labels}
									customer_name={this.state.customer_name}
									selected_module={props.match.params.module}
									view="Edit"
									record_id={props.match.params.id}
								/>
                            )}
                        />
						<Route
							basename={PortalConfig.portal_url}
							path={`${process.env.PUBLIC_URL}/:module/create`}
                            render={(props) => (
								<Portal
									{...props}
									logged_in={this.state.logged_in}
									updateLoginStatus={this.updateLoginStatus}
									modules={this.state.modules}
                                    module_labels={this.state.module_labels}
									customer_name={this.state.customer_name}
									selected_module={props.match.params.module}
									view="Edit"
									record_id="new"
								/>
                            )}
                        />
						<Route
							basename={PortalConfig.portal_url}
							path={`${process.env.PUBLIC_URL}/:module`}
                            render={(props) => (
								<Portal
									{...props}
									logged_in={this.state.logged_in}
									updateLoginStatus={this.updateLoginStatus}
									modules={this.state.modules}
                                    module_labels={this.state.module_labels}
									customer_name={this.state.customer_name}
									selected_module={props.match.params.module}
									page="1"
									view="List"
								/>
                            )}
                        />
                    </Switch>
				</Router>
			</div>
		);
	}
}

export default App;
