/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { HomeIcon, BookOpenIcon, SupportIcon, DocumentTextIcon, DocumentIcon, UserGroupIcon, CurrencyDollarIcon } from '@heroicons/react/outline';
import MobileNavSlider from './MobileNavSlider';
import ProfileDropDown from './ProfileDropDown';
import Nav from './Nav';
import MobileTopNav from './MobileTopNav';
import ContainerHead from './ContainerHead';
import Logo from '../../../../images/logo.png';
import PortalConfig from "../../../../data/config";

function MainLayout(props)
{
    const [moduleLabel, setModuleLabel] = useState('');
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [navigation, setNavigation] = useState([]);
	const uploaded_logo = PortalConfig.logo;

	useEffect(() => {
		let tmpNavigation = [];
		// Prepare navigation array
		tmpNavigation.push({
			name: 'Dashboard',
			href: `${process.env.PUBLIC_URL}/`,
			icon: HomeIcon,
			current: !props.selected_module ? true : false,
		});

		props.modules.forEach((module_name) => {
			let moduleIcon = DocumentIcon;
            if(module_name === 'Documents') {
                moduleIcon = DocumentTextIcon;
            }
            else if(module_name === 'Cases') {
                moduleIcon = SupportIcon;
            }
            else if(module_name === 'knowledge_base') {
                moduleIcon = BookOpenIcon;
            }
            else if(module_name === 'Meetings') {
                moduleIcon = UserGroupIcon;
            }
			else if(module_name === 'AOS_Invoices') {
				moduleIcon = CurrencyDollarIcon;
			}

			tmpNavigation.push({
				name: props.moduleLabels[module_name],
				href: `${process.env.PUBLIC_URL}/${module_name}`,
				icon: moduleIcon,
				current: props.selected_module && props.selected_module === module_name ? true : false,
			});

            if(props.selected_module === module_name) {
                setModuleLabel(props.moduleLabels[module_name]);
            }
		});

		setNavigation(tmpNavigation);
  	}, []);

	/**
	 * Update current nav
	 *
	 * @param {string} module_name
	 */
	function updateCurrentNav(module_name) {
        let module_label = '';
		let tmpNavigation = navigation;
		tmpNavigation.forEach((nav_info) => {
			nav_info.current = false;
			if(nav_info['name'] === module_name) {
				nav_info.current = true;
                module_label = nav_info['name'];
			}
		});

        setModuleLabel(module_label);

		setNavigation(tmpNavigation);
	}

    return (
        <>
			<MobileNavSlider navigation={navigation} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} updateCurrentNav={updateCurrentNav} />
			<div className="hidden md:flex md:flex-shrink-0">
				{/* Sidebar starts here */}
        		<div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100">
            		<div className="flex items-center flex-shrink-0 px-6 justify-center">
                    	<img
                    		className="h-8 w-auto"
							src={uploaded_logo ? uploaded_logo : Logo}
                        	alt="Logo"
                    	/>
                	</div>
					<div className="h-0 flex-1 flex flex-col overflow-y-auto">
						<ProfileDropDown customer_name={props.customer_name} updateLoginStatus={props.updateLoginStatus} />
						<Nav navigation={navigation} updateCurrentNav={updateCurrentNav} />
					</div>
				</div>
				{/* Sidebar ends here */}
			</div>
          	<div className="flex flex-col w-0 flex-1 overflow-hidden">
				<MobileTopNav customer_name={props.customer_name} updateLoginStatus={props.updateLoginStatus} setSidebarOpen={setSidebarOpen} />
					<main className="flex-1 relative z-0 overflow-y-auto focus:outline-none pb-10">
						<ContainerHead
							record_id={props.record_id}
							currentView={props.currentView}
							loading={props.loading}
							setLoading={props.setLoading}
							setSearchValue={props.setSearchValue}
							updateLoginStatus={props.updateLoginStatus}
							advancedSearch={props.advancedSearch}
							setAdvancedSearch={props.setAdvancedSearch}
							setSelectedFilter={props.setSelectedFilter}
							selected_module={props.selected_module}
                            moduleLabel={moduleLabel}
						/>
						<div className="px-4 mt-6 sm:px-6 lg:px-8">
            				{props.view}
						</div>
				</main>
          	</div>
        </>
    );
}

export default MainLayout;
